// Setup const to know if we need to be using Ensighten 'New' tracking
import { CATEGORY_FEATURED } from 'constants/Actions';

export const parseData = (data) => {
  // Needs to convert data to lowercase
  // I think it aslo needs to remove certain characters / not sure what tho at the moment
  return (data || '').toLowerCase();
};

export const ENSIGHTEN_NEW = typeof window.ensightenTracking != 'undefined' && window.ensightenTracking.type == 'new' ? true : false;

export const getDataLayer = () => {
  return { ...window.trackingVariables };
};

const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset();
  const o = Math.abs(offset);
  return offset === 0 ? '' : (offset < 0 ? '+' : '-') + Math.floor(o / 60);
};

const getVersionDateFormat = () => {
  // format expected (DOW: Day of Week) - "<web"|{DOW}|{Month}|{Day}|{Year}|HH:MM:SS}|{TimeZone}>
  // "web|monday|01|09|2020|16:00:00|UTC"
  const date = new Date();
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const dow = days[date.getDay()];
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const hour = date.getHours().toString();
  const mins = date.getMinutes().toString();
  const secs = date.getSeconds().toString();
  const time = `${hour.length == 1 ? '0' : ''}${hour}:${mins.length === 1 ? '0' : ''}${mins}:${secs.length == 1 ? '0' : ''}${secs}`;
  return `web|${dow}|${month}|${day}|${year}|${time}|UTC${getTimeZone()}`;
};

// Data sets to be used for tracking
export const INTERACTION_FEATURED_DATA = {
  interaction: 'featured thumbnail click',
  featured_content: '',
  featuredcontent_event: 1,
  interaction_event: 1,
  contentclick_event: 1,
};

export const INTERACTION_WEB_GAME_DATA = {
  franchise: '',
  game_title: '',
  english_name: '',
  interaction: 'game thumbnail click',
  interaction_event: 1,
  gameclicked_event: 1,
  contentclick_event: 1,
};

export const INTERACTION_VIDEO_DATA = {
  franchise: '',
  video_title: '',
  english_name: '',
  interaction: 'video thumbnail click',
  interaction_event: 1,
  videoclicked_event: 1,
  contentclick_event: 1,
};

export const PAGEVIEW_CATEGORY_DATA = {
  pageview_event: 1,
};

export const INTERACTION_CATEGORY_DATA = {
  english_name: 'nvs',
  featured_content: '',
  interaction_event: 1,
};

export const track = function (props) {
  const { content_type } = props;
  if (ENSIGHTEN_NEW) {
    switch (content_type) {
      case 'app':
      case 'web_game':
        triggerEnsightenTrackingWebGame(props);
        break;
      case 'video':
        triggerEnsightenTrackingVideo(props);
        break;
      default:
        break;
    }
  }
};

export const triggerEnsightenTrackingFeature = function (props) {
  const { englishname = '', title = '', showdata, content_type, description = '' } = props;
  const { name_english = '' } = showdata;
  const franchise = name_english && name_english !== '' ? name_english.trim().toLowerCase() : 'nvs';

  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_FEATURED_DATA, ...dataLayer };
    data.franchise = franchise;
    data.content_type = 'featured content';
    data.featured_content = description.toLowerCase();
    delete data.pageview_event;

    if (content_type == 'web_game') {
      data.game_title = englishname.toLowerCase();
    } else {
      data.video_title = englishname.toLowerCase();
    }
    data.version = getVersionDateFormat();
    console.log(data);
    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

export const triggerEnsightenTrackingWebGame = function (props) {
  const { english_name, title = '', showdata, description } = props;
  const { name_english = '' } = showdata;
  const franchise = name_english && name_english !== '' ? name_english.trim().toLowerCase() : 'nvs';
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_WEB_GAME_DATA, ...dataLayer };
    data.game_title = description.toLowerCase();
    data.english_name = english_name ? english_name.toLowerCase() : title.toLowerCase();
    data.franchise = franchise;
    delete data.pageview_event;
    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * content_title is clicked content, not current page title
     */
    data.content_title = data.game_title;
    data.version = getVersionDateFormat();

    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

export const triggerEnsightenTrackingVideo = function (props) {
  // console.log('triggerEnsightenTrackingVideo', JSON.stringify(props));
  const { english_name, title = '', showdata, description } = props;
  const { name_english = '' } = showdata;
  const franchise = name_english && name_english !== '' ? name_english.trim().toLowerCase() : 'nvs';
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_VIDEO_DATA, ...dataLayer };
    data.video_title = description.toLowerCase();
    data.video_category = 'nvs';
    data.english_name = english_name ? english_name.toLowerCase() : title.toLowerCase();
    data.franchise = franchise;
    /*
     * https://jollywise.atlassian.net/browse/TAI-258
     * content_title is clicked content, not current page title
     */
    data.content_title = data.video_title;
    delete data.pageview_event;
    data.version = getVersionDateFormat();

    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

const updateDataBySubCategory = (data, subCategory) => {
  let page_name = data.page_name;
  if (subCategory === CATEGORY_FEATURED) {
    // we are returning to main games page
    data.subsection = 'games|main';
    data.content_type = 'game';
    if (page_name.includes('category')) {
      // remove category data, restoring to base page_name
      const ary = page_name.split('/category');
      page_name = ary[0];
    }
  } else {
    // we are in game category page
    data.subsection = 'games|category';
    data.content_type = 'section front';
    if (page_name.includes('category')) {
      // replace existing category
      const ary = page_name.split('/');
      ary.pop();
      page_name = ary.join('/') + '/' + subCategory.toLowerCase();
    } else {
      // add selected category to base page_name
      page_name = page_name + '/category/' + subCategory.toLowerCase();
    }
  }
  data.page_name = page_name;
};

export const triggerEnsightenTrackingCategoryInteraction = function (props) {
  const { category, subCategory, nextSubCategory, description = '' } = props;
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...INTERACTION_CATEGORY_DATA, ...dataLayer };
    updateDataBySubCategory(data, subCategory);
    data.content_title = data.content_title === '' ? 'nvs' : data.content_title;
    data.interaction = `${category} category filter`;

    if (category === 'game') {
      data.gamecategoryfilter = nextSubCategory.toLowerCase();
      data.gamefilterclick_event = 1;

      data.featured_content = description.toLowerCase();
      data.screenorientation = window.trackingVariables.screenorientation;
      data.english_name = nextSubCategory.toLowerCase();
      data.previous_page = 'nvs';
      data.interaction = 'game category filter';
      data.interaction_event = 1;
      data.game_category = nextSubCategory.toLowerCase();
    }
    delete data.pageview_event;
    data.version = getVersionDateFormat();

    turner_metadata.trackAction.push({ type: 'interaction', data });
  }
};

export const triggerEnsightenTrackingCategoryPageView = function (props) {
  const { category, subCategory } = props;
  if (ENSIGHTEN_NEW) {
    const dataLayer = getDataLayer();
    const data = { ...PAGEVIEW_CATEGORY_DATA, ...dataLayer };
    updateDataBySubCategory(data, subCategory);
    data.content_title = data.content_title === '' ? 'nvs' : data.content_title;
    if (category === 'game' && subCategory !== CATEGORY_FEATURED) {
      data.gamecategorypage_event = 1;
      data.game_category = subCategory.toLowerCase();
    }
    data.version = getVersionDateFormat();

    turner_metadata.trackAction.push({ type: 'pageview', data });
  }
};
