import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAdTechTag, getAdType, getDoubleclickESID, getDoubleclickID, getEnsightenID, getEnsightenTrigger, getScriptURL } from 'store/ads/adsSelectors';
import * as ADS from 'constants/Ads';
import DoubleClickAd from 'components/ads/DoubleClickAd';
import AdtechAd from 'components/ads/AdtechAd';
import ScriptAd from 'components/ads/ScriptAd';
import ScriptRawAd from 'components/ads/ScriptRawAd';
import EnsightenAd from 'components/ads/EnsightenAd';
import { LOG } from 'constants/App';

class Ad extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { advertising_slug, adType, adTag, doubleclickID, doubleclickESID, scriptURL, ensightenID, ensightenTrigger } = this.props;
    let ad = null;
    let enabled = true;
    const randomID = 'scripturl_' + Math.round(Math.random() * 1000);
    switch (adType) {
      case ADS.ADTECH:
        ad = <AdtechAd tag={adTag} />;
        break;
      case ADS.SCRIPT:
        ad = <ScriptAd id={randomID} tag={adTag} scriptURL={scriptURL} />;
        break;
      case ADS.SCRIPT_RAW:
        ad = <ScriptRawAd id={randomID} tag={adTag} scriptURL={scriptURL} />;
        break;
      case ADS.DOUBLECLICK:
        ad = <DoubleClickAd id={doubleclickID} />;
        break;
      case ADS.ENSIGHTEN:
        ad = <EnsightenAd id={ensightenID} trigger={ensightenTrigger} />;
        break;
      case ADS.DOUBLECLICK_ES:
        ad = <DoubleClickAd id={doubleclickESID} />;
        break;
      default:
        ad = null;
        enabled = true; // force render an empty spot for undefined ad type
    }
    LOG.enabled && console.log(`%c++++++ Ad.render | adType ${adType}`, LOG.clrBlack);
    /**
     * This needs to be deferred until after ad libs have instantiated
     */
    if (!enabled) {
      return null;
    } else {
      return (
        <div className="featured_item mpu platform_all">
          <div className="mpu_inner">
            {ad}
            <div className="mpu_label">{advertising_slug}</div>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    adType: getAdType(state),
    doubleclickID: getDoubleclickID(state),
    doubleclickESID: getDoubleclickESID(state),
    adTag: getAdTechTag(state),
    scriptURL: getScriptURL(state),
    ensightenID: getEnsightenID(state),
    ensightenTrigger: getEnsightenTrigger(state),
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(Ad);
