import React from 'react';

export const MoreButton = ({ pageId, buttonClick, label }) => {
  const moreButtonClass = `more_button more_button--${pageId} show-more-items`;

  return (
    <section>
      <a className={moreButtonClass} onClick={buttonClick}>
        <div className="more_button__inner">
          <span className="icon">+</span>
          <span>{label}</span>
        </div>
      </a>
    </section>
  );
};
