/* global __WATCH__:false */
import { DYNAMIC_URL, FRIENDLY_URLS, FRONTEND_URL, LANGUAGE_TEXTS, STATIC_URL, ICON_ALTS } from 'constants/App';
import { CONTENT_TYPE } from 'constants/Const';
import { parseURLForRedirect } from 'store/models/EntryParser';

const ICON_STAR = STATIC_URL + `base/images/icons/star.svg`;
const ICON_VIDEO = STATIC_URL + `base/images/icons/videos.svg`;
const ICON_COMIC = STATIC_URL + `base/images/icons/comics.svg`;
const ICON_GAME = STATIC_URL + `base/images/icons/games.svg`;
const ICON_ACTIVITY = STATIC_URL + `base/images/icons/activities.svg`;
const ICON_APP = STATIC_URL + `base/images/icons/app.svg`;
const ICON_CHARACTER = STATIC_URL + `base/images/icons/characters.svg`;

const parseCharacters = (o, data) => {
  const { friendly_url, show = {} } = o;
  const { friendly_url: showUrl } = show;
  data.icon = ICON_CHARACTER;
  data.link = FRONTEND_URL + FRIENDLY_URLS.shows + '/' + showUrl + '/' + FRIENDLY_URLS.characters + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.CHARACTER;
  return data;
};

const parseAnimalsActivity = (o, data) => {
  const { friendly_url } = o;
  data.icon = ICON_ACTIVITY;
  data.link = FRONTEND_URL + FRIENDLY_URLS.animalsactivity + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.ACTIVITIY;
  return data;
};

const parseLetsCreateActivity = (o, data) => {
  const { friendly_url } = o;
  data.icon = ICON_ACTIVITY;
  data.link = FRONTEND_URL + FRIENDLY_URLS.letscreateactivity + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.ACTIVITIY;
  return data;
};

const parseAnimalsVideo = (o, data) => {
  const { friendly_url } = o;
  data.icon = ICON_VIDEO;
  data.link = FRONTEND_URL + FRIENDLY_URLS.animalsvideo + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.VIDEO;
  return data;
};

const parseLetsCreateVideo = (o, data) => {
  const { friendly_url } = o;
  data.icon = ICON_VIDEO;
  data.link = FRONTEND_URL + FRIENDLY_URLS.letscreatevideo + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.VIDEO;
  return data;
};

const parseVideo = (o, data) => {
  const { friendly_url, title, show = {} } = o;
  const { name = '' } = show;
  data.title = name;
  data.description = title;
  data.icon = ICON_VIDEO;
  data.link = FRONTEND_URL + FRIENDLY_URLS.video + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.VIDEO;
  return data;
};

const parseComic = (o, data) => {
  const { friendly_url } = o;
  data.icon = ICON_COMIC;
  data.link = FRONTEND_URL + FRIENDLY_URLS.comic + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.COMIC;
  return data;
};

const parseGame = (o, data) => {
  const { name, friendly_url } = o;
  data.icon = ICON_GAME;
  data.link = FRONTEND_URL + FRIENDLY_URLS.game + '/' + friendly_url;
  const template = LANGUAGE_TEXTS.content_game_attribute_title || '{game-title}';
  data.iconAlt = ICON_ALTS.GAME;
  data.titleAttribute = template.includes('{game-title}') ? template.replace('{game-title}', name) : name;
  return data;
};

const parseCampaign = (o, data) => {
  const { friendly_url } = o;
  data.icon = ICON_STAR;
  data.link = FRONTEND_URL + FRIENDLY_URLS.campaigns + '/' + friendly_url;
  data.iconAlt = ICON_ALTS.CAMPAIGN;
  return data;
};

const parseQuiz = (o, data) => {
  const { quiz_type, friendly_url } = o;
  const iconType = 'quiz_' + quiz_type;
  data.icon = STATIC_URL + `base/images/icons/${iconType}.svg`;
  data.link = FRONTEND_URL + FRIENDLY_URLS.quiz + '/' + friendly_url;
  data.quiz_type = quiz_type;
  data.iconAlt = ICON_ALTS.QUIZ;
  return data;
};

const parseApp = (o, data) => {
  const { friendly_url, show = null, cbmultiapp } = o;
  data.icon = ICON_APP;
  data.link = FRONTEND_URL + FRIENDLY_URLS.app + '/' + friendly_url;
  data.title = show === null ? cbmultiapp : show.name;
  data.iconAlt = ICON_ALTS.APP;
  return data;
  // const { name, show, store_links } = o;
  //
  // const template = LANGUAGE_TEXTS.content_app_badge_attribute_title || '{app-title}';
  // const titleAttribute = template.includes('{app-title}') ? template.replace('{app-title}', name) : name;
  //
  // let platforms = [];
  // for (const obj of store_links) {
  //   const { store } = obj;
  //   const { platform } = store;
  //   const link = parseURLForRedirect(obj.link);
  //   const icon = DYNAMIC_URL + store.image;
  //   const amc_store = store.name;
  //   const amc_show = (show && show.tracking_name) || '';
  //   const amc_title = '';
  //   const amc_redirect = 'self';
  //   const amc_exit_track = obj.link;
  //   const target = '_blank';
  //   platforms.push({ id: platform, titleAttribute, platform, link, icon, target, amc_store, amc_show, amc_title, amc_redirect, amc_exit_track });
  // }
  //
  // const hasUserPlatform = platforms.reduce((acc, val) => {
  //   return !acc && val.id === USER_PLATFORM ? true : acc;
  // }, false);
  // if (USER_PLATFORM === PLATFORM_OTHER || !hasUserPlatform) {
  //   // if desktop or user platform unrecognised then keep all platform links
  //   data.platforms = platforms;
  // } else {
  //   const platform = platforms.find((x) => x.id === USER_PLATFORM);
  //   if (platform) {
  //     data.platforms = [platform];
  //   } else {
  //     data.platforms = platforms;
  //   }
  // }
  // data.target = '_blank'; // defaults to new window
  // // __WATCH__ && console.log('parseApp', data, o);
  // return data;
};

const parseEditorial = (o, data) => {
  const { quiz_type, friendly_url, image_small, image_medium, image_large } = o;
  data.icon = ICON_STAR;
  data.iconAlt = ICON_ALTS.STAR;
  data.link = FRONTEND_URL + FRIENDLY_URLS.editorials + '/' + friendly_url;
  data.quiz_type = quiz_type;
  data.img_small = DYNAMIC_URL + image_small;
  data.img_medium = DYNAMIC_URL + image_medium;
  data.img_large = DYNAMIC_URL + image_large;
  return data;
};

const parseManual = (o, data) => {
  const { title, description, short_description, url, target, promo_image_small, promo_image_medium, promo_image_large, image_small, image_medium, image_large, url_internal = true } = o;
  data.title = title;
  data.description = description || short_description;
  data.icon = ICON_STAR;
  data.iconAlt = ICON_ALTS.STAR;
  data.link = parseURLForRedirect(url, url_internal);
  data.target = target;
  data.img_small = promo_image_small ? DYNAMIC_URL + promo_image_small : DYNAMIC_URL + image_small;
  data.img_medium = promo_image_medium ? DYNAMIC_URL + promo_image_medium : DYNAMIC_URL + image_medium;
  data.img_large = promo_image_large ? DYNAMIC_URL + promo_image_large : DYNAMIC_URL + image_large;
  return data;
};

const parseContentHub = (o, data) => {
  const { title, description, friendly_url, image_small, image_medium, image_large } = o;
  data.title = title;
  data.description = description;
  data.icon = ICON_STAR;
  data.iconAlt = ICON_ALTS.STAR;
  data.link = FRONTEND_URL + friendly_url;
  data.img_small = DYNAMIC_URL + image_small;
  data.img_medium = DYNAMIC_URL + image_medium;
  data.img_large = DYNAMIC_URL + image_large;
  return data;
};

const parseMPU = (o, data) => {
  data.isMPU = true;
  return data;
};

export const parseEntryContentType = (content_type, entryData, o) => {
  switch (content_type) {
    case CONTENT_TYPE.CHARACTER:
      entryData = parseCharacters(o, entryData);
      break;
    case CONTENT_TYPE.ANIMALS_ACTIVITY:
      entryData = parseAnimalsActivity(o, entryData);
      break;
    case CONTENT_TYPE.LETSCREATE_ACTIVITY:
      entryData = parseLetsCreateActivity(o, entryData);
      break;
    case CONTENT_TYPE.ANIMALS_VIDEO:
      entryData = parseAnimalsVideo(o, entryData);
      break;
    case CONTENT_TYPE.LETSCREATE_VIDEO:
      entryData = parseLetsCreateVideo(o, entryData);
      break;
    case CONTENT_TYPE.VIDEO:
      entryData = parseVideo(o, entryData);
      break;
    case CONTENT_TYPE.COMIC:
      entryData = parseComic(o, entryData);
      break;
    case CONTENT_TYPE.WEB_GAME:
      entryData = parseGame(o, entryData);
      break;
    case CONTENT_TYPE.CAMPAIGN:
      entryData = parseCampaign(o, entryData);
      break;
    case CONTENT_TYPE.QUIZ:
      entryData = parseQuiz(o, entryData);
      break;
    case CONTENT_TYPE.APP:
      entryData = parseApp(o, entryData);
      break;
    case CONTENT_TYPE.EDITORIAL:
      entryData = parseEditorial(o, entryData);
      break;
    case CONTENT_TYPE.MANUAL:
      entryData = parseManual(o, entryData);
      break;
    case CONTENT_TYPE.HUB:
      entryData = parseContentHub(o, entryData);
      break;
    case CONTENT_TYPE.MPU:
      entryData = parseMPU(o, entryData);
      break;
  }
  return entryData;
};
