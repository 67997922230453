import React from 'react';
import { DEVICE, USE_WEBP } from 'constants/App';

//stackoverflow.com/questions/5573096/detecting-webp-support
export const canUseWebP = () => {
  const elem = document.createElement('canvas');
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
    const testString = !(window.mozInnerScreenX == null) ? 'png' : 'webp';
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/' + testString) == 0;
  }
  // very old browser like IE 8, canvas not supported
  return false;
};

const convertFileExtensionToWebp = (url) => {
  if (!url) {
    return '';
  }
  return USE_WEBP ? url.replace(new RegExp('.(' + ['jpg', 'jpeg', 'png'].join('|') + ')$', 'i'), '.webp') : url;
};

export const processImages = (placeholder, images, widths = ['1x', '2x', '3x']) => {
  const imgs = images.map((image) => {
    return convertFileExtensionToWebp(image);
  });
  const srcSet = imgs.reduce((acc, image, index) => {
    if (image && image !== '') {
      const width = widths[index] || '';
      acc += `${image} ${width}`;
      if (index < imgs.length - 1) {
        acc += ', ';
      }
    }
    return acc;
  }, '');
  const placeholder_lazy = convertFileExtensionToWebp(placeholder);
  const src = DEVICE === 'desktop' ? imgs[2] : imgs[0];
  return { placeholder_lazy, srcSet, src };
};

const getFileType = (item) => {
  return item.substr(item.lastIndexOf('.') + 1).toLowerCase();
};

// export const createImageSrcSet = (images, widths = ['1x', '2x', '3x']) => {
//   const sizes = '100vw';
//   const VALID = 'jpg,jpeg,png';
//   const fileType = getFileType(images[0]);
//   let webpFormat;
//   if (VALID.includes(fileType) && IS_USING_WEBP) {
//     webpFormat = images.map((image, index) => {
//       const width = widths[index] || '';
//       return `${convertFileExtensionToWebp(image)} ${width}`;
//     });
//   } else {
//     console.info('createImageSrcSet | ignoring gif, svg and webp');
//   }
//
//   const mainFormat = images.map((image, index) => {
//     const width = widths[index] || '';
//     return `${image} ${width}`;
//   });
//
//   return (
//     <>
//       {webpFormat && <source type="image/webp" srcSet={webpFormat.toString()} sizes={sizes} />}
//       <source srcSet={mainFormat.toString()} sizes={sizes} />
//     </>
//   );
// };
