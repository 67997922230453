import React, { Component } from 'react';
import { LOG } from 'constants/App';

class ScriptRawAd extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // TODO : Need to embed the script (this is used in Boing fr) : example:
    //  <ins data-zone="31883" className="byadthink"></ins>
    //  <script async src="//ad.adxcore.com/adjs_r.php?async&amp;what=zone:31883&amp;inf=no"></script>
  }

  render() {
    const { id, tag, scriptURL } = this.props;
    LOG.enabled && console.log(`%c++++++ [ScriptRawAd] | id ${id} | tag ${tag} | scriptURL ${scriptURL}`, LOG.clrBlack);
    return <div id={id} style={{ textAlign: 'center', margin: '0 auto' }} />;
  }
}
export default ScriptRawAd;
